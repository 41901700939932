import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import { useExperiments } from '@wix/yoshi-flow-editor';
import { TpaSettingsProviderForWidget } from '@wix/tpa-settings/react';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import CategoryMenuNew from '../category-menu-new';
import CategoryMenuOld from '../category-menu-old';
import styles from './app-root.scss';

const AppRoot = withReduxStore((props) => {
  const { experiments } = useExperiments();
  return (
    <ResponsiveListener
      dataHook="category-menu-root"
      className={styles.baseStyles}
    >
      {experiments.enabled(
        OOI_EXPERIMENTS.USE_NEW_CATEGORY_MENU_SETTINGS_URL,
      ) ? (
        <TpaSettingsProviderForWidget
          isRTL={props.isRTL}
          isMobile={props.isMobile}
          publicData={props.publicData}
          stylesParams={props.stylesParams}
          hostStyle={props.host.style}
        >
          <CategoryMenuNew />
        </TpaSettingsProviderForWidget>
      ) : (
        <CategoryMenuOld />
      )}
      <AppLoaded />
    </ResponsiveListener>
  );
});

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.object.isRequired,
  sectionUrl: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  sectionUrl: getSectionUrl(state),
});

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
