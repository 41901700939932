import React, { PropsWithChildren } from 'react';
import styles from './link-text.scss';

type Props = PropsWithChildren<{
  postCount?: React.ReactNode;
  a11yText?: React.ReactNode;
}>;

const LinkText: React.FC<Props> = ({ children, postCount, a11yText }) => {
  if (postCount) {
    return (
      <>
        {children} <span aria-hidden={true}>{postCount}</span>{' '}
        <span className={styles.srOnly}>{a11yText}</span>
      </>
    );
  }

  return <>{children}</>;
};

export default LinkText;
