import React from 'react';
import classNames from 'classnames';
import {
  LINK_LIST_LAYOUT_MOBILE_PARAMS,
  LINK_LIST_LAYOUT_PARAMS,
  LINK_LIST_TEXT_ALIGNMENT,
  SECTION_BLOG_PAGE,
} from '@wix/communities-blog-client-common';
import Link from '../common/components/link/internal-link';
import { useSelector } from '../common/components/runtime-context';
import { getTextAlignment } from './selectors/link-list-selectors';
import styles from './link-list.scss';

type LinkListProps = {
  links?: {
    key: string;
    path: string;
    text: React.JSX.Element;
  }[];
  emptyState?: { text: string };
  sectionId?: string;
  useMobileDesignSettings: boolean;
  useMobileLayoutSettings: boolean;
};

const APP_SETTING_TO_ALIGNMENT: Record<number, string> = {
  [LINK_LIST_TEXT_ALIGNMENT.left]: 'alignLeft',
  [LINK_LIST_TEXT_ALIGNMENT.center]: 'alignCenter',
  [LINK_LIST_TEXT_ALIGNMENT.right]: 'alignRight',
};

const LinkList: React.FC<LinkListProps> = ({
  links = [],
  emptyState = { text: '' },
  sectionId = SECTION_BLOG_PAGE,
  useMobileDesignSettings = false,
  useMobileLayoutSettings = false,
}) => {
  const layoutParams = useMobileLayoutSettings
    ? LINK_LIST_LAYOUT_MOBILE_PARAMS
    : LINK_LIST_LAYOUT_PARAMS;

  const alignment = useSelector((state) =>
    getTextAlignment(state, layoutParams),
  );

  const textAlignment = APP_SETTING_TO_ALIGNMENT[alignment];

  const listClass = classNames(styles.list, {
    [styles.mobile]: useMobileDesignSettings,
    [styles.mobilePadding]: useMobileLayoutSettings,
  });

  return (
    <ul data-hook="link-list" className={listClass}>
      {links.length > 0 ? (
        links.map(({ key, path, text }) => (
          <li data-hook="link-list-item" key={key} className={styles.item}>
            <Link
              to={path}
              sectionId={sectionId}
              addHoverClasses={false}
              className={classNames(styles.link, styles[textAlignment])}
            >
              <span className={styles.text}>{text}</span>
            </Link>
          </li>
        ))
      ) : (
        <li className={classNames(styles.message, styles[textAlignment])}>
          <span className={styles.text}>{emptyState.text}</span>
        </li>
      )}
    </ul>
  );
};

export default LinkList;
