import {
  LINK_LIST_LAYOUT_MOBILE_PARAMS,
  LINK_LIST_LAYOUT_PARAMS,
} from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import type { AppState } from '../../common/types';

type LayoutParams =
  | typeof LINK_LIST_LAYOUT_PARAMS
  | typeof LINK_LIST_LAYOUT_MOBILE_PARAMS;

export const getTextAlignment = (state: AppState, layoutParams: LayoutParams) =>
  getAppSettingsValue({
    state,
    key: layoutParams.textAlignment.appSettingsPath,
    fallback: layoutParams.textAlignment.defaultAlignment,
  });
