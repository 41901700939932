import { createSelector } from 'reselect';
import { AppState } from '../../common/types';
import { getShowAllPostsCategory } from '../selectors/category-menu-selectors';

export const getCategoryMenuWidgetData = (state: AppState) =>
  state.categoryMenuWidget;

export const getAllPostsFeedLabel = (state: AppState) =>
  state.tpaSettings.settings?.allPostsFeedLabel;

export const getCategoryMenu = createSelector(
  getCategoryMenuWidgetData,
  getShowAllPostsCategory,
  getAllPostsFeedLabel,
  (categoryMenu, showAllPosts, allPostsFeedLabel) => {
    const parsedCategoryMenu = {
      ...categoryMenu,
      categoryMenu: [...categoryMenu.categoryMenu],
    };

    if (showAllPosts && categoryMenu.categoryMenu[0].display) {
      parsedCategoryMenu.categoryMenu[0].display.text =
        allPostsFeedLabel as string;
    } else {
      parsedCategoryMenu.categoryMenu = categoryMenu.categoryMenu.slice(1);
    }

    return parsedCategoryMenu;
  },
);
