import {
  createStylesParams,
  StyleParamType,
  type IStyleParam,
} from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import * as LEGACYSTYLEPARAMS from './legacyStyleParams';

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  return {
    showPostCount: styleParams.booleans.showPostCountNew ? 'inline' : 'none',
    showAllPostCategory: styleParams.booleans.showAllPostsCategoryNew
      ? 'block'
      : 'none',
  };
};

type GetStyleParamType = {
  isMobile: boolean;
  getStyleParamValue: (param: IStyleParam) => any;
  paramName: IStyleParam;
  mobileParamName: IStyleParam;
  dependencyParam: IStyleParam;
};

const resolveDefaultStyleParamValue = ({
  isMobile,
  getStyleParamValue,
  paramName,
  mobileParamName,
  dependencyParam,
}: GetStyleParamType) => {
  if (isMobile) {
    const isMobileSettingsEnabled = getStyleParamValue(dependencyParam);
    if (isMobileSettingsEnabled) {
      const mobileParamValue = getStyleParamValue(mobileParamName);
      if (typeof mobileParamValue !== 'undefined') {
        return mobileParamValue;
      }
    }
  }
  return getStyleParamValue(paramName);
};

export default createStylesParams<{
  showAllPostsCategoryNew: StyleParamType.Boolean;
  showPostCountNew: StyleParamType.Boolean;
  backgroundColorHoverNew: StyleParamType.Color;
  textColorHoverNew: StyleParamType.Color;
  cornerRadiusNew: StyleParamType.Number;
  borderWidthNew: StyleParamType.Number;
  borderColorNew: StyleParamType.Color;
  separatorWidthNew: StyleParamType.Number;
  separatorColorNew: StyleParamType.Color;
  backgroundColorNew: StyleParamType.Color;
  textFontNew: StyleParamType.Font;
  textColorNew: StyleParamType.Color;
  textAlignmentNew: StyleParamType.Number;
  textPaddingHorizontalNew: StyleParamType.Number;
  textPaddingVerticalNew: StyleParamType.Number;
}>({
  showAllPostsCategoryNew: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAllPostsCategory,
        mobileParamName: LEGACYSTYLEPARAMS.showAllPostsCategoryMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showPostCountNew: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostCount,
        mobileParamName: LEGACYSTYLEPARAMS.showPostCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  backgroundColorHoverNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.backgroundColorHover,
        mobileParamName: LEGACYSTYLEPARAMS.backgroundColorHoverMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textColorHoverNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textColorHover,
        mobileParamName: LEGACYSTYLEPARAMS.textColorHoverMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textFontNew: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textFont,
        mobileParamName: LEGACYSTYLEPARAMS.textFontMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  cornerRadiusNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.cornerRadius,
        mobileParamName: LEGACYSTYLEPARAMS.cornerRadiusMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  borderWidthNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.borderWidth,
        mobileParamName: LEGACYSTYLEPARAMS.borderWidthMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  borderColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.borderColor,
        mobileParamName: LEGACYSTYLEPARAMS.borderColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  separatorWidthNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.separatorWidth,
        mobileParamName: LEGACYSTYLEPARAMS.separatorWidthMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  separatorColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.separatorColor,
        mobileParamName: LEGACYSTYLEPARAMS.separatorColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  backgroundColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.backgroundColor,
        mobileParamName: LEGACYSTYLEPARAMS.backgroundColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textColor,
        mobileParamName: LEGACYSTYLEPARAMS.textColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textAlignmentNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textAlignment,
        mobileParamName: LEGACYSTYLEPARAMS.textAlignmentMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  textPaddingHorizontalNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textPaddingHorizontal,
        mobileParamName: LEGACYSTYLEPARAMS.textPaddingHorizontalMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  textPaddingVerticalNew: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textPaddingVertical,
        mobileParamName: LEGACYSTYLEPARAMS.textPaddingVerticalMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
});
