import React from 'react';
import classnames from 'classnames';
import {
  LINK_LIST_TEXT_ALIGNMENT,
  SECTION_BLOG_PAGE,
} from '@wix/communities-blog-client-common';
import Link from '../../../common/components/link/internal-link';
import styles from './link-list.scss';

const APP_SETTING_TO_ALIGNMENT: Record<number, string> = {
  [LINK_LIST_TEXT_ALIGNMENT.left]: 'alignLeft',
  [LINK_LIST_TEXT_ALIGNMENT.center]: 'alignCenter',
  [LINK_LIST_TEXT_ALIGNMENT.right]: 'alignRight',
};

type LinkListProps = {
  links?: {
    key: string;
    path: string;
    text: React.JSX.Element;
  }[];
  emptyState?: { text: string };
  sectionId?: string;
  alignment?: number;
};

const LinkList: React.FC<LinkListProps> = ({
  links = [],
  emptyState = { text: '' },
  sectionId = SECTION_BLOG_PAGE,
  alignment,
}) => {
  const textAlignment = APP_SETTING_TO_ALIGNMENT[alignment ?? 0];

  return (
    <ul data-hook="link-list" className={styles.list}>
      {links.length > 0 ? (
        links.map(({ key, path, text }, index) => (
          <li
            data-hook="link-list-item"
            key={key}
            className={classnames(styles.item, {
              [styles.first]: index === 0,
            })}
          >
            <Link
              to={path}
              sectionId={sectionId}
              addHoverClasses={false}
              className={classnames(styles.link, styles[textAlignment])}
            >
              <span className={styles.text}>{text}</span>
            </Link>
          </li>
        ))
      ) : (
        <li className={classnames(styles.message, styles[textAlignment])}>
          <span className={styles.text}>{emptyState.text}</span>
        </li>
      )}
    </ul>
  );
};

export default LinkList;
